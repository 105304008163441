import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Layout2Columns from './layout-2col';

function CompanyInfo({ className, classNameTexts}) {
    return (
        <main className={classNames("flex flex-col flex-1 w-full max-w-6xl px-4 mx-auto md:px-8 ", className)}>
            <section id={"company-info"}>
                <Layout2Columns className="mx-8 md:mx-16">
                    <ul className={classNames("text-sm mx-8 md:mx-16", classNameTexts)}>
                        <li>Ataks s.r.o.</li>
                        <li></li>
                        <li>Janka Alexyho 3355/11 </li>
                        <li>Bratislava 841 01 </li>
                        <li>Slovakia</li>
                    </ul>
                    <ul className={classNames("text-sm mx-8 md:mx-16", classNameTexts)}>
                        <li>Company ID Number:  43 791 000</li>
                        <li>VAT Number: 2022480372</li>
                        {/* <li>IBAN:  </li> */}
                    </ul>
                </Layout2Columns>
            </section>
        </main>
    );
}


CompanyInfo.defaultProps = {
    id: "",
    className: "",
    classNameTexts: "",
};

CompanyInfo.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    classNameTexts: PropTypes.string,
};

export default CompanyInfo;
