import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SectionMain({ children, id, className}) {
    return (
        <main className={classNames("flex flex-col flex-1 w-full max-w-6xl px-4 mx-auto md:px-8 ", className)}>
            <section id={id}>
                {children}
            </section>
        </main>
    );
}


SectionMain.defaultProps = {
    id: "",
    className: "",
};

SectionMain.propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default SectionMain;
