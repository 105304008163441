import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import logo from "../images/ataks-logo.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (<div>

    {/* <nav className="flex flex-wrap items-center justify-between p-4 shadow">
      <div className="lg:order-1 w-auto lg:w-1/5 lg:text-center">
        <a className="text-xl text-acc-500 font-semibold" href="#">Ataks</a>
      </div>
      <div className="block lg:hidden">
        <button className="navbar-burger flex items-center py-2 px-3 text-gray-900 rounded border border-gray-900">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>
      </div>
      <div className="navbar-menu hidden lg:order-2 lg:block w-full lg:w-2/5 lg:text-center">
        <p className="block hide lg:inline-block mt-4 lg:mt-0 mr-10 uppercase text-xs align-top text-blue-900 hover:text-acc-700">Products</p>
        <a className="block lg:inline-block mt-4 lg:mt-0 mr-10 text-blue-900 hover:text-acc-700" href="#">Triggers</a>
        <a className="block lg:inline-block mt-4 lg:mt-0 text-blue-900 hover:text-acc-700" href="#">Flairosol</a>
      </div>
      <div className="navbar-menu hidden lg:order-3 lg:block w-full lg:w-2/5 lg:text-right">
        <a className="block lg:inline-block mt-4 lg:mt-0 mr-10 text-blue-900 hover:text-acc-700 is-active:underline hover:underline" href="#">About</a>
        <a className="block lg:inline-block mt-4 lg:mt-0 mr-10 text-blue-900 hover:text-acc-700 hover:underline" href="#">Facebook</a>
        <a className="block lg:inline-block mt-4 lg:mt-0 text-blue-900 hover:text-acc-700" href="#">Contact</a>
      </div>
    </nav> */}
    <header className="bg-white shadow sticky">
      <div className="flex flex-wrap items-center justify-between max-w-6xl  mx-auto">
        <Link to="/">
          <img
            alt={site.siteMetadata.title}
            className="h-10 object-contain m-6 font-bold text-magenta  self-center"
            src={logo}
          />
        </Link>

        <button
          className="items-center block px-3 py-2 text-gray border border-gray-900 rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
            } md:block mx-6 md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/`,
              title: `About`,
            },
            {
              route: `/#flairosol`,
              title: `Flairosol`,
            },
            {
              route: `/#opus`,
              title: `Triggers`,
            },
            {
              route: `/#contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block text-gray-900 hover:text-acc md:inline-block md:mt-0 md:ml-6 "
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  </div>
  );
}

export default Header;
