import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function Separator ({ className }){
    return (<div className={classNames("mt-12", className)} />
    )
}

Separator.defaultProps = {
    className: "",
};

Separator.propTypes = {
    className: PropTypes.string,
};

export default Separator;