import React from "react";
import PropTypes from "prop-types";
import Header from "./header";
import SectionMain from "./section-main";
import CompanyInfo from "./company-info";
import Separator from "./separator";

function LayoutPage({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      {children}


      <footer className="bg-gray-700">
        <Separator />
        {/* <SectionMain className="">
          <CompanyInfo classNameTexts="text-white" />
        </SectionMain> */}
        <Separator />
        <hr className="text-white" />
        <nav className="  max-w-4xl p-4 mx-auto text-sm md:p-8 text-right">
          <p className="text-white self-right">
            @{` `}
            <a
              className="font-narrow no-underline"
              href="https://lupu.sk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ataks 2020
            </a>
          </p>

        </nav>
      </footer>
    </div>
  );
}

LayoutPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutPage;
